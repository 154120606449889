import './index.scss';
import { Button, Input, Message, Form } from '@arco-design/web-react';
import { useLocation, useNavigate } from '@modern-js/runtime/router';
import { ReactNode, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { validator } from '@utils/validator';
import { useCountDown } from 'ahooks';
import { IconLeft } from '@arco-design/web-react/icon';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { fetchResetUserPassword } from '@api/copilot_api';
import useLocale from '@/hooks/useLocale';
import { encryptRSA } from '@/utils/rsa';
import SimpleHead from '@/components/simple-head';
import { RequireAuth } from '@/routes/auth';
import { sendVerify } from '@/api/copilot_api';
import { ErrorCode } from '@/api/errno';
import { GlobalContext } from '@/routes/context';
import { ModuleName, PageEvent, PageNameConfig, ResetPasswordEvent } from '@/config/track.config';
import { useVisibilityChange } from '@/hooks/useVisibilityChange';

const formItemLayout = {
  wrapperCol: {
    span: 24,
  },
};

const PasswordRest = () => {
  const locale = useLocale();
  const [form] = Form.useForm();
  const location = useLocation();
  const { collectEvent } = useContext(GlobalContext);

  const navigate = useNavigate();
  const timeRef = useRef(dayjs().unix());
  const documentVisible = useVisibilityChange();

  const [isCountdownEnd, setIsCountdownEnd] = useState(false);
  const [, setConfirmLogin] = useState(false);
  const [targetDate, setTargetDate] = useState<number>();
  const [distinctId, SetDistinctId] = useState('');
  const [isDefaultValue, setIsDefaultValue] = useState(true);

  const newPassword = Form.useWatch('newPassword', form);
  const confirmPassword = Form.useWatch('confirmPassword', form);
  const captchaVal = Form.useWatch('captcha', form);

  const pathname = location.state?.from?.pathname;
  const from = pathname || '/writing';

  const pageName = PageNameConfig.PWD_RESET;
  const fromLink = pathname ? from : '';

  useLayoutEffect(() => {
    if (documentVisible) {
      timeRef.current = dayjs().unix();
      collectEvent?.([
        {
          event: PageEvent.PAGE_SHOW,
          params: {
            fromLink,
            pageName,
          },
        },
      ]);
    } else {
      onUnMounted();
    }
  }, [documentVisible]);

  const onUnMounted = () => {
    collectEvent?.([
      {
        event: PageEvent.PAGE_HIDE,
        params: {
          fromLink,
          pageName,
          duration: `${dayjs().unix() - timeRef.current}`,
        },
      },
    ]);
  };

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.?!@#$%^&*()_+\-=]).{8,}$/;

  const [countdown] = useCountDown({
    targetDate,
    onEnd: () => {
      setIsCountdownEnd(() => false);
    },
  });

  const checkConfirmPassword = (value: string | undefined, callback: (error?: ReactNode) => void) => {
    if (!value) {
      callback(locale.pwd_change_password_required);
      return;
    }

    if (newPassword !== value) {
      callback(locale.pwd_change_password_match_error);
    } else {
      callback();
    }
  };

  const onSendCode = async () => {
    try {
      setTargetDate(Date.now() + 3000 * 10);
      setIsCountdownEnd(() => true);
      setIsDefaultValue(() => false);
      const data = await sendVerify({});
      SetDistinctId(() => data?.distinctId || '');
      Message.success(locale.pwd_change_status_verificationnumber_sending);
      collectEvent?.([
        {
          event: ResetPasswordEvent.CAPTCHA_SEND,
          params: {
            pageName,
            status: 'success',
            distinctId: data.distinctId || '',
            moduleName: ModuleName.PWD_RESET_FORM,
          },
        },
      ]);
    } catch (error: any) {
      const errorCode = error.code;
      collectEvent?.([
        {
          event: ResetPasswordEvent.CAPTCHA_SEND,
          params: {
            pageName,
            errorCode,
            message: error.msg,
            status: 'fail',
            moduleName: ModuleName.PWD_RESET_FORM,
          },
        },
      ]);
      // 用户验证码已发送 请等待接收
      if (ErrorCode.H_ERR_VERIFY_CODE_SENDING === errorCode) {
        Message.error(locale.pwd_change_err_verify_code_sending);
        return;
      }
      //   用户验证码发送上限 禁止发送
      if (ErrorCode.H_ERR_VERIFY_CODE_LIMIT === errorCode) {
        Message.error(locale.pwd_change_err_verify_code_limit);
        return;
      }
      //
      Message.error(`code: ${error.code}, msg:${error.msg}`);
    }
  };

  // 提交表单
  const onSubmit = async (values: any) => {
    setConfirmLogin(() => true);
    try {
      const { newPassword, captcha = '' } = values;
      const rsaPassWord = encryptRSA(newPassword);
      console.log('captcha:', captcha, 'distinctId:', distinctId, form.getFieldValue('captcha'));
      await fetchResetUserPassword({
        captcha,
        newPassword: rsaPassWord,
        distinctId,
      });
      localStorage.clear();
      sessionStorage.clear();
      Message.success(locale.pwd_change_password_success);
      collectEvent?.([
        {
          event: ResetPasswordEvent.CLICK_RESET_PASSWORD_BUTTON,
          params: {
            pageName,
            status: 'success',
            redirectUrl: 'login',
            newPasswordLength: newPassword.length,
            moduleName: ModuleName.PWD_RESET_FORM,
          },
        },
      ]);
      navigate('/login', { replace: true });
    } catch (error: any) {
      const errorCode = error.code;
      collectEvent?.([
        {
          event: ResetPasswordEvent.CLICK_RESET_PASSWORD_BUTTON,
          params: {
            pageName,
            errorCode,
            message: error.msg,
            status: 'fail',
            moduleName: ModuleName.PWD_RESET_FORM,
          },
        },
      ]);
      // 验证码不匹配
      if (ErrorCode.H_ERR_VERIFY_CODE === errorCode) {
        Message.error(locale.pwd_change_status_verification_number_failed);
        return;
      }
      //  历史密码重复错误
      if (ErrorCode.H_ERR_USER_PASSWORD_DUPLICATION === errorCode) {
        Message.error(locale.pwd_change_duplication);
        return;
      }

      // 验证码无效
      if (ErrorCode.H_ERR_VERIFY_CODE_INVALID === errorCode) {
        Message.error(locale.pwd_change_err_verify_code_invalid);
        return;
      }

      Message.error(`code: ${error.code}, msg:${error.msg}`);
    } finally {
      setConfirmLogin(() => false);
    }
  };

  const onBack = () => {
    const pathname = location.state?.from?.pathname || '/writing';
    if (pathname) {
      navigate(pathname, { replace: true });
    } else {
      window.history.back();
    }
  };

  const verifyButtonText = useMemo(() => {
    if (isDefaultValue) {
      return locale.pwd_change_input_resend_button_default_value;
    }
    return locale.pwd_change_input_resend_button;
  }, [isDefaultValue, locale]);

  const isSendCodeDisabled = !(confirmPassword && newPassword) || confirmPassword !== newPassword || isCountdownEnd || !passwordRegex.test(newPassword);

  const isConfirmDisabled = !(confirmPassword && newPassword) || confirmPassword !== newPassword || !passwordRegex.test(newPassword);

  return (
    <RequireAuth>
      <>
        <SimpleHead />
        <div className="page-body flex justify-center items-center  w-full">
          <div className="password-reset flex flex-col justify-center items-center">
            <IconLeft onClick={onBack} className="cancel-button" />
            <div className="password-reset__title  text-center"> {locale.pwd_reset_title}</div>
            <div className="line"></div>
            <Form {...formItemLayout} form={form} size="large" autoComplete="off" onSubmit={onSubmit} className="password-from">
              <Form.Item
                field="newPassword"
                rules={[
                  {
                    validator: validator.checkPassword,
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input.Password placeholder={locale.pwd_change_input_new_pwd_placeholder} tabIndex={1} maxLength={15} />
              </Form.Item>
              <Form.Item
                field="confirmPassword"
                rules={[
                  {
                    validator: checkConfirmPassword,
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input.Password placeholder={locale.pwd_change_input_confirm_pwd_placeholder} tabIndex={2} maxLength={15} />
              </Form.Item>
              <Form.Item>
                <div className="flex  items-center">
                  <Form.Item field="captcha" style={{ width: '362px' }}>
                    <Input className="input-text" placeholder={locale.pwd_change_input_verification_placeholder} tabIndex={3} />
                  </Form.Item>
                  <Form.Item style={{ width: '80px' }}>
                    <Button
                      className={classNames('verify-button', {
                        'verify-button-dis': !(confirmPassword && newPassword) || confirmPassword !== newPassword || isCountdownEnd || !passwordRegex.test(newPassword),
                      })}
                      onClick={onSendCode}
                      disabled={isSendCodeDisabled}
                    >
                      {isCountdownEnd ? `${locale.pwd_change_input_resend_button} ${Math.round(countdown / 1000)}s` : verifyButtonText}
                    </Button>
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item>
                <Button long type="primary" htmlType="submit" className="submit-button" disabled={!(captchaVal && !isConfirmDisabled)}>
                  {locale.pwd_reset_input_submit}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </>
    </RequireAuth>
  );
};

export default PasswordRest;
